import { preventDefault } from '@/std/browser'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'

export type AuthFormModel = {
  readonly values: FormGroup<{
    username: FormControl<string, 'Required'>
    password: FormControl<string, 'Required'>
  }>
  submit: () => void
}

type FormValues = { username: string; password: string }

type Deps = {
  initialValues?: FormValues
  onDone: (values: FormValues) => void
}
export const AuthFormModel = ({ initialValues, onDone }: Deps) => {
  const values = FormGroup({
    username: FormControl(initialValues?.username ?? '', [
      nonEmpty('Required'),
    ]),
    password: FormControl(initialValues?.password ?? '', [
      nonEmpty('Required'),
    ]),
  })
  const model: AuthFormModel = {
    values,
    submit: preventDefault(() => {
      if (!values.isValid()) return
      onDone({
        username: values.username(),
        password: values.password(),
      })
    }),
  }
  return model
}
