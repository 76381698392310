import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { Opaque } from 'type-fest'
import { PalId } from './Pal'

export type PalsStoryId = Opaque<string, 'PalsStoryId'>
export const PalsStoryId = pipe(
  T.string,
  T.map(cast<string, PalsStoryId>, String),
)

export type PalsStory = {
  id: PalsStoryId
  palIds: PalId[]
  createdAt: Date
  updatedAt: Date
  date: Date
  story: string
}

const type = T.struct({
  id: PalsStoryId,
  palIds: T.array(PalId),
  date: T.DateFromString,
  createdAt: T.DateFromString,
  updatedAt: T.DateFromString,
  story: T.string,
})
type Ord = { ord: typeof type.ord }

export const PalsStory: T.Type<PalsStory> & Ord = type
