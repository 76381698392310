import { solidFormControl } from '@/solidjs/form'
import { Input } from '@/solidjs/form/Input'
import { solidState } from '@/solidjs/state'
import { O, list } from '@/std/data'
import { pipe } from '@/std/function'
import { ComponentProps, splitProps } from 'solid-js'
import { PalsListModel } from './PalsList'

type Props = ComponentProps<'div'> & {
  model: PalsListModel
}
export const PalsList = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const pals = solidState(props.model.list)
  const search = solidFormControl(props.model.search)
  return (
    <div {...rest}>
      <div class="flex gap a-center">
        <h3>Pals</h3>
        <Input type="search" control={search} placeholder="Search…" />
      </div>
      {pipe(pals(), (pals) => (
        <ul class="flex column gap-s">
          {pipe(
            pals,
            list.toNonEmpty,
            O.fold(
              () => (
                <li>
                  {search()
                    ? 'No pals matches the search'
                    : 'No Pals (add one?)'}
                </li>
              ),
              list.map(({ pal, relation }) => (
                <li class="flex j-between">
                  <div>
                    {pal.firstName} {pal.lastName}
                  </div>
                  <div class="flex gap-s">
                    <button
                      onClick={() => props.model.onEditPal({ pal, relation })}
                    >
                      ✎
                    </button>
                  </div>
                </li>
              )),
            ),
          )}
        </ul>
      ))}
    </div>
  )
}
