import { O } from '@/std/data'
import { T } from '@/std/type'
import { PalId } from './Pal'

export type Relation = {
  /** 'oldest' … in DB. In terms of creation date */
  oldest: PalId
  /** 'youngest' … in DB. In terms of creation date */
  youngest: PalId
  relationship: Relationship
  createdAt: Date
  updatedAt: Date
}
export type Relationship =
  | { type: 'family'; nature: string }
  | {
      type: 'friend' | 'acquaintance' | 'professional' | 'partner'
      since: Date
      until: O.Option<Date>
    }

const Relationship: T.Type<Relationship> = T.union(
  T.struct({ type: T.literal('family'), nature: T.string }),
  T.struct({
    type: T.literal('acquaintance', 'friend', 'professional', 'partner'),
    since: T.DateFromString,
    until: T.option(T.DateFromString),
  }),
)

const type = T.struct({
  oldest: PalId,
  youngest: PalId,
  relationship: Relationship,
  createdAt: T.DateFromString,
  updatedAt: T.DateFromString,
})
type Ord = { ord: typeof type.ord }

export const Relation: T.Type<Relation> & Ord = type
