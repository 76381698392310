import { O } from '@/std/data'
import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { Opaque } from 'type-fest'

export type PalId = Opaque<string, 'PersonId'>

export type Pal = {
  id: PalId
  firstName: string
  lastName: string
  birthDate: O.Option<Date>
  deathDate: O.Option<Date>
  createdAt: Date
  updatedAt: Date
}

export const PalId = pipe(T.string, T.map(cast<string, PalId>, String))

const type = T.struct({
  id: PalId,
  firstName: T.string,
  lastName: T.string,
  birthDate: T.option(T.DateFromString),
  deathDate: T.option(T.DateFromString),
  createdAt: T.DateFromString,
  updatedAt: T.DateFromString,
})
type Ord = { ord: typeof type.ord }

export const Pal: T.Type<Pal> & Ord = type
