import { C } from '@/std/codec'
import { O, list, parseJson, struct } from '@/std/data'
import { InfraError } from '@/std/error'
import { curry2, pipe } from '@/std/function'
import { HttpError } from '@/std/http'
import { TR } from '@/std/remote'
import { T } from '@/std/type'
import { UnlisteddbClient } from '@unlisteddb/client'
import { Pal, PalId } from '../entity/Pal'
import { Relation } from '../entity/PalRelation'
import { PalsStory } from '../entity/PalsStory'

export const listPalsOf = curry2(
  (palId: PalId, db: DB): { pal: Pal; relation: Relation }[] => {
    return db.relations
      .filter((r) => r.oldest === palId || r.youngest === palId)
      .map((r) => ({
        id: r.youngest === palId ? r.oldest : r.youngest,
        relation: r,
      }))
      .map(({ relation, id }) => ({
        pal: db.pals[id],
        relation,
      }))
      .filter(Boolean)
  },
)

export const listPalsOfSelf =
  (db: DB) =>
  (search: string): { pal: Pal; relation: Relation }[] =>
    listPalsOf(db.selfPalId, db).filter(
      ({ pal }) =>
        pal.id === search ||
        `${pal.firstName} ${pal.lastName}`
          .toLowerCase()
          .includes(search.toLowerCase()),
    )

export const listStoriesOf = curry2((db: DB, palId: PalId) => {
  return db.stories
    .filter((story) => story.palIds.includes(palId))
    .sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf())
})

export const putPal = curry2(
  (db: DB, options: { pal: Pal; relation: Relation }): DB => {
    const { pal, relation } = options
    const self = db.pals[db.selfPalId]
    const existing = db.relations.find(
      (r) => r.oldest === self.id && r.youngest === pal.id,
    )
    if (existing) Object.assign(existing, relation)
    return {
      ...db,
      pals: { ...db.pals, [pal.id]: pal },
      relations: existing ? db.relations : [...db.relations, relation],
    }
  },
)
export const putPalsStory = curry2((db: DB, story: PalsStory): DB => {
  return {
    ...db,
    stories: pipe(
      db.stories,
      list.filter((s) => s.id !== story.id),
      list.prepend(story),
      (array) =>
        array.sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf()),
    ),
  }
})

export const removePalsStory = (db: DB, story: PalsStory): DB => {
  return {
    ...db,
    stories: db.stories.filter((s) => s.id !== story.id),
  }
}

// const client = makeUnlisteddbClient(makeFetchClient('http://localhost:9182'))

export type DB = {
  selfPalId: PalId
  pals: Record<PalId, Pal>
  stories: PalsStory[]
  relations: Relation[]
}

const DB: T.Type<DB> = T.struct({
  selfPalId: PalId,
  pals: T.record(PalId, Pal),
  stories: T.array(PalsStory),
  relations: T.array(Relation),
})

type PalApi = {
  updateDb: (
    db: DB,
  ) => TR.TaskResult<HttpError | InfraError | C.DecodeError, DB>
  getDb: () => TR.TaskResult<
    HttpError | InfraError | C.DecodeError,
    O.Option<DB>
  >
}

type Deps = {
  client: UnlisteddbClient
  username: string
  password: string
}
const database = 'pals.json'
export const UnlisteddbPalApi = ({
  client,
  username,
  password,
}: Deps): PalApi => {
  return {
    getDb: () =>
      pipe(
        client.get({ params: { username, database } }),
        TR.map(struct.lookup('body')),
        TR.mapResult(parseJson),
        TR.mapResult(DB.decode),
        TR.map(O.Some),
        TR.or((err) => {
          if (err.id === 'HttpError' && err.code === 404) return TR.Ok(O.None())
          return TR.Err(err)
        }),
      ),
    updateDb: (db) =>
      pipe(
        client.put({
          params: { username, database },
          body: { password, content: DB.encode(db) },
        }),
        TR.map(() => db),
      ),
  }
}
