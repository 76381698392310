import {
  DateInput,
  InputFeedback,
  TextArea,
  TextInput,
  solidFormControl,
  solidFormGroup,
} from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { list } from '@/std/data'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Dropdown } from '@palnet/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'
import { PalStoryFormModel } from './PalStoryForm'

type Props = ComponentProps<'form'> & {
  model: PalStoryFormModel
}

export const PalStoryForm = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const values = solidFormGroup(props.model.values)
  const search = solidFormControl(props.model.search)
  const submitState = solidState(props.model.submitState)
  const palOptions = solidState(props.model.palOptions)

  return (
    <form {...rest} onSubmit={props.model.submit}>
      <header>
        <h2>New Story</h2>
      </header>

      <fieldset>
        <label for="palIds">Involved Pals</label>

        <Dropdown
          trigger={<TextInput control={search} />}
          menu={
            <Dropdown.Menu>
              {pipe(
                palOptions().unselected,
                list.map((option) => (
                  <button
                    type="button"
                    role="option"
                    onClick={() => {
                      values.palIds.update(list.append(option.value))
                      search.set('')
                    }}
                  >
                    {option.label}
                  </button>
                )),
              )}
            </Dropdown.Menu>
          }
        />
        <InputFeedback control={values.palIds} />

        <div class="flex gap-s">
          {pipe(
            palOptions().selected,
            list.map((option) => (
              <button
                type="button"
                class="chip"
                onClick={() => {
                  values.palIds.update(list.filter((id) => id !== option.value))
                  search.set('')
                }}
              >
                <span>{option.label}</span>
                <span>×</span>
              </button>
            )),
          )}
        </div>
      </fieldset>

      <fieldset>
        <label id="storyDate">Date</label>
        <DateInput id="storyDate" control={values.date} />
        <InputFeedback control={values.date} />
      </fieldset>

      <fieldset>
        <label for="story">Story</label>
        <TextArea id="story" rows={10} control={values.story} />
        <InputFeedback control={values.story} />
      </fieldset>

      <footer class="flex j-center">
        <button
          class="button"
          type="submit"
          disabled={RR.isPending(submitState())}
        >
          Save
        </button>
      </footer>
    </form>
  )
}
