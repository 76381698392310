import { ComponentProps, createEffect, splitProps } from 'solid-js'

type Props = ComponentProps<'dialog'>
export const _Dialog = (props: Props) => {
  const [, rest] = splitProps(props, ['open'])
  let element: HTMLDialogElement | undefined = undefined
  createEffect(() => {
    if (props.open === element?.open) return
    props.open ? element?.showModal() : element?.close()
  })
  const onClickAway = (event: MouseEvent) => {
    const target = event.target
    if (!props.open || !(target instanceof Node) || !element) return

    const isInDialog = Array.from(element.children).some((el) =>
      el.contains(target),
    )
    if (!isInDialog && props.open) element.close()
  }
  return <dialog {...rest} onClick={onClickAway} ref={element} />
}
