import { makeBrowserHistory } from '@/std/browser'
import { render } from 'solid-js/web'
import { AppModel } from './app'
import { App } from './app.solid'
import { config } from './config'

const root = document.getElementById('root')
if (!root) throw new Error('missing root element to mount app')

const app = AppModel({
  history: makeBrowserHistory(),
  config,
  userDataStorageType: 'local',
})

render(() => <App model={app} />, root)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/build/service-worker.js')
    .catch((error) =>
      console.log(`Service Worker registration failed: "${error}"`),
    )
}
