import { preventDefault } from '@/std/browser'
import { O, list, record } from '@/std/data'
import { FormControl, FormGroup, nonEmpty, required } from '@/std/form-control'
import { pipe } from '@/std/function'
import { ReadonlyState, computed, mapState } from '@/std/reactivity'
import { RR, RemoteAction, TR } from '@/std/remote'
import { uuid } from '@/std/uuid'
import { Pal, PalId } from '../../entity/Pal'
import { PalsStory, PalsStoryId } from '../../entity/PalsStory'
import { DB, putPalsStory } from '../api'

export type PalStoryFormModel = {
  readonly values: FormGroup<{
    palIds: FormControl<PalId[], 'required'>
    date: FormControl<O.Option<Date>, 'required'>
    story: FormControl<string, 'required'>
  }>
  readonly submitState: ReadonlyState<RR.RemoteResult<unknown, unknown>>
  readonly palOptions: ReadonlyState<{
    selected: PalOption[]
    unselected: PalOption[]
  }>
  readonly search: FormControl<string>

  submit: () => Promise<void>
}

type PalOption = { label: string; value: PalId }

type Deps = {
  db: DB
  sync: (db: DB) => TR.TaskResult<unknown, unknown>
  initialValues?: PalsStory
}
export const PalStoryFormModel = ({ db, sync, initialValues }: Deps) => {
  const search = FormControl('')
  const values = FormGroup({
    palIds: FormControl(initialValues?.palIds ?? [], [nonEmpty('required')]),
    date: FormControl(O.Some(initialValues?.date ?? new Date()), [
      required('required'),
    ]),
    story: FormControl(initialValues?.story ?? '', [nonEmpty('required')]),
  })
  const palOptions = PalOptions()
  const action = RemoteAction(sync)
  const model: PalStoryFormModel = {
    values,
    search,
    palOptions: computed([palOptions, values.palIds], (options, palIds) =>
      pipe(
        options,
        list.separate(({ value }) => palIds.includes(value)),
        ([selected, unselected]) => ({ selected, unselected }),
      ),
    ),

    submitState: action.state,
    submit: preventDefault(async () => {
      if (!values.isValid()) return
      const next = putPalsStory(db, {
        id: initialValues?.id ?? (uuid() as PalsStoryId),
        createdAt: initialValues?.createdAt ?? new Date(),
        date: O.toUndefined(values.date())!,
        palIds: values.palIds(),
        story: values.story(),
        updatedAt: new Date(),
      })
      await action.trigger(next)
    }),
  }

  return model

  function PalOptions() {
    return mapState(search, (search) =>
      pipe(
        db.pals,
        record.values,
        list.filter((pal) => pal.id !== db.selfPalId),
        list.filter(palMatches(search)),
        list.map((pal) => ({
          label: `${pal.firstName} ${pal.lastName[0]}.`,
          value: pal.id,
        })),
      ),
    )
  }
}

const palMatches = (search: string) => (pal: Pal) => {
  search = search.toLowerCase()
  const name = `${pal.firstName} ${pal.lastName}`.toLowerCase()
  return name.includes(search)
}
