import { O, date, list, struct } from '@/std/data'
import { FormControl } from '@/std/form-control'
import { pipe } from '@/std/function'
import { ReadonlyState, mapState } from '@/std/reactivity'
import { TR } from '@/std/remote'
import { RemoteActionToConfirm } from '@/std/remote/remote-action'
import { Pal } from '../../entity/Pal'
import { PalsStory } from '../../entity/PalsStory'
import { DB, removePalsStory } from '../api'

export type PalStoryListModel = {
  readonly stories: ReadonlyState<{ pals: Pal[]; story: PalsStory }[]>
  readonly search: FormControl<string>
  readonly removeStoryAction: RemoteActionToConfirm<
    unknown,
    unknown,
    [PalsStory]
  >
  onEditStory: (story: PalsStory) => void
}

type Deps = {
  db: DB
  onEditStory: (story: PalsStory) => void
  sync: (db: DB) => TR.TaskResult<unknown, unknown>
}
export const PalStoryListModel = ({ db, sync, onEditStory }: Deps) => {
  const search = FormControl<string>('')
  const model: PalStoryListModel = {
    onEditStory,
    removeStoryAction: RemoteActionToConfirm((story) => {
      return sync(removePalsStory(db, story))
    }),
    search,
    stories: mapState(search, (search) =>
      pipe(
        db.stories,
        list.map((story) => ({
          story,
          date: story.date,
          pals: pipe(
            story.palIds,
            list.filterMap((palId) =>
              pipe(
                O.fromNullable(db.pals[palId]),
                O.flatMap(O.fromPredicate(matchesSearch(search))),
              ),
            ),
          ),
        })),
        list.filter(({ pals }) => pals.length > 0),
        list.sortReverse(struct.ord('date', date.ord)),
      ),
    ),
  }

  return model
}

const matchesSearch = (search: string) => (pal: Pal) => {
  search = search.toLowerCase()
  const name = `${pal.firstName} ${pal.lastName}`.toLowerCase()
  return name.includes(search)
}
