import { TextInput, solidFormControl } from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { O, date, list } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { Dialog } from '@palnet/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'
import { PalStoryListModel } from './PalStoryList'

type Props = ComponentProps<'div'> & {
  model: PalStoryListModel
}
export const PalStoryList = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const events = solidState(props.model.stories)
  const search = solidFormControl(props.model.search)
  const storyToRemove = solidState(props.model.removeStoryAction.toConfirm)
  return (
    <div {...rest}>
      {pipe(
        storyToRemove(),
        O.fold(constNull, ([story]) => (
          <Dialog
            open
            class="flex column gap"
            onClose={props.model.removeStoryAction.cancel}
          >
            <header>
              <h2>Are you sure?</h2>
            </header>
            <hr />
            <p>
              You are about to delete the following story:
              <br />
              <strong>{story.story}</strong>
            </p>
            <hr />
            <footer class="flex j-between gap">
              <button
                type="button"
                class="danger"
                onClick={props.model.removeStoryAction.confirm}
              >
                Yes!
              </button>
              <button
                type="button"
                onClick={props.model.removeStoryAction.cancel}
              >
                No
              </button>
            </footer>
          </Dialog>
        )),
      )}

      <fieldset>
        <label for="search">Filter by Name</label>
        <TextInput id="search" placeholder="Joe Bloggs" control={search} />
      </fieldset>
      <hr />
      {pipe(
        events(),
        list.toNonEmpty,
        O.fold(
          () => (
            <div>{search() ? 'No events matches the search' : 'No events'}</div>
          ),
          (events) => (
            <div class="flex column gap">
              {events.map(({ pals, story }) => (
                <div class="flex column gap-s">
                  <div class="flex gap-s j-between a-start">
                    <strong class="flex column">
                      {pals.map((pal) => (
                        <span>
                          {pal.firstName} {pal.lastName[0]}.
                        </span>
                      ))}
                    </strong>
                    <div class="flex gap-s a-center">
                      <strong class="small capitalize">
                        {date.format(story.date, { dateStyle: 'full' })}
                      </strong>
                      <button
                        type="button"
                        class="compact"
                        onClick={() => props.model.onEditStory(story)}
                      >
                        ✎
                      </button>
                      <button
                        type="button"
                        class="compact danger"
                        onClick={() => {
                          props.model.removeStoryAction.trigger(story)
                        }}
                      >
                        ×
                      </button>
                    </div>
                  </div>

                  <div>{story.story}</div>
                </div>
              ))}
            </div>
          ),
        ),
      )}
    </div>
  )
}
