import { History, StorageType, makeStorage } from '@/std/browser'
import { O } from '@/std/data'
import { makeFetchClient } from '@/std/http'
import { ReadonlyState, State, mapState } from '@/std/reactivity'
import { T } from '@/std/type'
import { PalsAppModel } from '@palnet/domain/pals/client/app'
import { makeUnlisteddbClient } from '@unlisteddb/client'
import { AuthFormModel } from './auth-form'
import { Config } from './config'

export type AppModel = {
  activeView: ReadonlyState<View>
}

const UserData = T.struct({ username: T.string, password: T.string })
type UserData = ReturnType<typeof UserData>

type View =
  | { name: 'auth'; model: AuthFormModel }
  | { name: 'palsApp'; model: PalsAppModel }

type Deps = {
  config: Config
  history: History
  userDataStorageType: StorageType
}
export const AppModel = ({
  config,
  history,
  userDataStorageType,
}: Deps): AppModel => {
  const auth = Auth({ userDataStorageType })
  const view = mapState(auth.userData, O.fold(AuthFormView, PalsAppView))

  return {
    activeView: view,
  }

  function PalsAppView({ username, password }: UserData): View {
    return {
      name: 'palsApp',
      model: PalsAppModel({
        client: makeUnlisteddbClient(makeFetchClient(config.apiUrl)),
        username,
        password,
        logOut: auth.logOut,
      }),
    }
  }

  function AuthFormView(): View {
    return {
      name: 'auth',
      model: AuthFormModel({ onDone: auth.logIn }),
    }
  }
}

const Auth = (options: { userDataStorageType: StorageType }) => {
  const userDataKey = 'user-data'
  const userDataStorage = makeStorage({
    type: options.userDataStorageType,
    Codec: UserData,
  })
  const userData = State(O.fromResult(userDataStorage.get(userDataKey)))
  const logIn = (data: UserData) => {
    userDataStorage.set(userDataKey, data)
    userData.set(O.Some(data))
  }
  const logOut = () => {
    userDataStorage.remove(userDataKey)
    userData.set(O.None())
  }

  return {
    logIn,
    logOut,
    userData,
  }
}
