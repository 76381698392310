import { InputFeedback, TextInput, solidFormGroup } from '@/solidjs/form'
import { Input } from '@/solidjs/form/Input'
import { ComponentProps, splitProps } from 'solid-js'
import { AuthFormModel } from './auth-form'

type Props = ComponentProps<'form'> & {
  model: AuthFormModel
}

export const AuthForm = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const values = solidFormGroup(props.model.values)

  return (
    <form {...rest} onSubmit={props.model.submit}>
      <fieldset>
        <label for="username">Username</label>
        <TextInput id="username" control={values.username} />
        <InputFeedback control={values.username} />
      </fieldset>

      <fieldset>
        <label for="password">Password</label>
        <Input type="password" id="password" control={values.password} />
        <InputFeedback control={values.password} />
      </fieldset>

      <footer class="flex j-center">
        <button class="button" type="submit">
          Go
        </button>
      </footer>
    </form>
  )
}
