import { O } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { ReadonlyState, State, computed } from '@/std/reactivity'
import { RR, RemoteResource, TR } from '@/std/remote'
import { UnlisteddbClient } from '@unlisteddb/client'
import { Pal } from '../entity/Pal'
import { Relation } from '../entity/PalRelation'
import { PalsStory } from '../entity/PalsStory'
import { DB, UnlisteddbPalApi } from './api'
import { PalFormModel } from './components/PalForm'
import { PalStoryFormModel } from './components/PalStoryForm'
import { PalStoryListModel } from './components/PalStoryList'
import { PalsListModel } from './components/PalsList'
import { RegisterFormModel } from './components/RegisterForm'

export type PalsAppModel = {
  resource: RemoteResource<any, any>
  view: ReadonlyState<RR.RemoteResult<unknown, PalsAppView>>
  viewStories: () => void
  viewList: () => void
  openPalsForm: (options?: { pal: Pal; relation: Relation }) => void
  openStoryForm: () => void
  logOut: () => void
}

type PalsAppView =
  | { name: 'register'; model: RegisterFormModel }
  | { name: 'list'; model: PalsListModel }
  | { name: 'stories'; model: PalStoryListModel }
  | { name: 'storyForm'; model: PalStoryFormModel }
  | { name: 'palForm'; model: PalFormModel }

type Deps = {
  client: UnlisteddbClient
  username: string
  password: string
  logOut: () => void
}

export const PalsAppModel = ({ client, password, username, logOut }: Deps) => {
  const api = UnlisteddbPalApi({ client, username, password })
  const db = RemoteResource(api.getDb)
  const manualView = State(O.None<PalsAppView>())
  const app: PalsAppModel = {
    resource: db,
    view: computed([db, manualView], (db, manualView) =>
      pipe(
        db,
        RR.map(
          O.fold(
            () => ({ name: 'register', model: RegisterFormModel({ sync }) }),
            (db) =>
              pipe(
                manualView,
                O.unwrapOr(() => ({
                  name: 'stories',
                  model: PalStoryListModel({
                    db,
                    onEditStory: openStoryForm,
                    sync: flow(sync, TR.tap(viewStories)),
                  }),
                })),
              ),
          ),
        ),
      ),
    ),
    viewStories,
    viewList,
    openPalsForm,
    openStoryForm,
    logOut,
  }
  return app

  function openPalsForm(options?: { pal: Pal; relation: Relation }) {
    const current = getCurrentDb()
    if (O.isNone(current)) return
    const form = PalFormModel({
      initialValues: options,
      db: flow(
        db,
        RR.map(O.unwrapOr(() => current.value)),
        RR.unwrapOr(() => current.value),
      ),
      sync: flow(sync, TR.tap(viewStories)),
    })
    manualView.set(O.Some({ name: 'palForm', model: form }))
  }

  function viewList() {
    const current = getCurrentDb()
    if (O.isNone(current)) return
    const model = PalsListModel({
      db: current.value,
      onEditPal: openPalsForm,
    })
    manualView.set(O.Some({ name: 'list', model }))
  }

  function viewStories() {
    const current = getCurrentDb()
    if (O.isNone(current)) return
    const model = PalStoryListModel({
      db: current.value,
      onEditStory: openStoryForm,
      sync: flow(sync, TR.tap(viewStories)),
    })
    manualView.set(O.Some({ name: 'stories', model }))
  }

  function openStoryForm(story?: PalsStory) {
    const current = getCurrentDb()
    if (O.isNone(current)) return
    const model = PalStoryFormModel({
      db: current.value,
      initialValues: story,
      sync: flow(sync, TR.tap(viewStories)),
    })
    manualView.set(O.Some({ name: 'storyForm', model }))
  }

  function sync(next: DB) {
    db.set(RR.PendingOk(O.Some(next)))
    return pipe(
      api.updateDb(next),
      TR.tap(() => db.set(RR.Ok(O.Some(next)))),
    )
  }

  function getCurrentDb() {
    return pipe(db(), RR.toOption, O.flatten)
  }
}
