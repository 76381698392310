import {
  DateInput,
  InputFeedback,
  SelectInput,
  TextInput,
  solidFormGroup,
} from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { RR } from '@/std/remote'
import { ComponentProps, Show, splitProps } from 'solid-js'
import { PalFormModel } from './PalForm'

type Props = ComponentProps<'form'> & {
  model: PalFormModel
}

export const PalForm = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const values = solidFormGroup(props.model.values)
  const submitState = solidState(props.model.submitState)

  return (
    <form class="flex column gap" {...rest} onSubmit={props.model.submit}>
      <fieldset>
        <label for="firstName">First Name</label>
        <TextInput
          id="firstName"
          placeholder="Jack"
          control={values.firstName}
        />
        <InputFeedback control={values.firstName} />
      </fieldset>

      <fieldset>
        <label for="birthDate">Last Name</label>
        <TextInput
          id="lastName"
          placeholder="Astonished"
          control={values.lastName}
        />
        <InputFeedback control={values.lastName} />
      </fieldset>

      <fieldset>
        <label for="birthDate">Birth Date</label>
        <DateInput id="birthDate" control={values.birthDate} />
      </fieldset>

      <fieldset>
        <label for="relationType">Relation Type</label>
        <SelectInput
          id="relationType"
          control={values.relationType}
          options={[
            { label: 'Friend', value: 'friend' },
            { label: 'Family', value: 'family' },
            { label: 'Acquaintance', value: 'acquaintance' },
            { label: 'Partner', value: 'partner' },
            { label: 'Pro', value: 'professional' },
          ]}
        />
      </fieldset>

      <Show when={values.relationType() === 'family'}>
        <fieldset>
          <label for="relationNature">Nature</label>
          <SelectInput
            id="relationNature"
            control={values.relationNature}
            options={[
              { label: 'Aunt/Uncle', value: 'aunt/uncle' },
              { label: 'Cousin', value: 'cousin' },
              { label: 'Sibling', value: 'sibling' },
              { label: 'Parent', value: 'parent' },
              { label: 'Grandparent', value: 'grandparent' },
              { label: 'Other', value: 'other' },
            ]}
          />
        </fieldset>
      </Show>

      <Show when={values.relationType() && values.relationType() !== 'family'}>
        <fieldset>
          <label for="relationSince">Since</label>
          <DateInput id="relationSince" control={values.relationSince} />
        </fieldset>

        <fieldset>
          <label for="relationUntil">Until</label>
          <DateInput id="relationUntil" control={values.relationUntil} />
        </fieldset>
      </Show>

      <footer class="flex j-center">
        <button
          type="submit"
          class="button"
          disabled={RR.isPending(submitState())}
        >
          Submit
        </button>
      </footer>
    </form>
  )
}
