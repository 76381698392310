import { solidState } from '@/solidjs/state'
import { match, pipe } from '@/std/function'
import { PalsApp } from '@palnet/domain/pals/client/app.solid'
import { AppModel } from './app'
import { AuthForm } from './auth-form.solid'

type Props = {
  model: AppModel
}
export const App = (props: Props) => {
  const activeView = solidState(props.model.activeView)
  return (
    <>
      {pipe(
        activeView(),
        match('name')({
          auth: ({ model }) => (
            <div
              class="page-background flex a-center j-center"
              style="block-size: 100vb"
            >
              <AuthForm class="card flex column gap" model={model} />
            </div>
          ),
          palsApp: ({ model }) => <PalsApp model={model} />,
        }),
      )}
    </>
  )
}
