import { preventDefault } from '@/std/browser'
import { O } from '@/std/data'
import { FormControl, FormGroup, nonEmpty, required } from '@/std/form-control'
import { TR } from '@/std/remote'
import { uuid } from '@/std/uuid'
import { Pal, PalId } from '../../entity/Pal'
import { DB } from '../api'

export type RegisterFormModel = {
  values: FormGroup<{
    firstName: FormControl<string, 'Required'>
    lastName: FormControl<string, 'Required'>
    birthDate: FormControl<O.Option<Date>, 'Required'>
  }>
  submit: () => Promise<void>
}

type Deps = {
  sync: (db: DB) => TR.TaskResult<unknown, unknown>
}
export const RegisterFormModel = ({ sync }: Deps) => {
  const model: RegisterFormModel = {
    values: FormGroup({
      firstName: FormControl('' as string, [nonEmpty('Required')]),
      lastName: FormControl('' as string, [nonEmpty('Required')]),
      birthDate: FormControl(O.None(), [required('Required')]),
    }),
    submit: preventDefault(async () => {
      if (!model.values.isValid()) return
      const pal: Pal = {
        id: uuid() as PalId,
        firstName: model.values.firstName(),
        lastName: model.values.lastName(),
        birthDate: model.values.birthDate(),
        deathDate: O.None(),
        createdAt: new Date(),
        updatedAt: new Date(),
      }
      const task = sync({
        selfPalId: pal.id,
        stories: [],
        pals: { [pal.id]: pal },
        relations: [],
      })
      await task()
    }),
  }

  return model
}
