import {
  DateInput,
  InputFeedback,
  TextInput,
  solidFormGroup,
} from '@/solidjs/form'
import { cx } from '@/std/classNames'
import { date } from '@/std/data'
import { pipe } from '@/std/function'
import { ComponentProps, splitProps } from 'solid-js'
import { RegisterFormModel } from './RegisterForm'

type Props = ComponentProps<'form'> & {
  model: RegisterFormModel
}
export const RegisterForm = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const values = solidFormGroup(props.model.values)

  return (
    <form
      {...rest}
      class={cx('flex column gap', props.class)}
      onSubmit={props.model.submit}
    >
      <fieldset>
        <label for="firstName">First Name</label>
        <TextInput
          id="firstName"
          placeholder="Joe"
          control={values.firstName}
        />
        <InputFeedback control={values.firstName} />
      </fieldset>

      <fieldset>
        <label for="lastName">Last Name</label>
        <TextInput
          id="lastName"
          placeholder="Bloggs"
          control={values.lastName}
        />
        <InputFeedback control={values.lastName} />
      </fieldset>

      <fieldset>
        <label for="birthDate">Birth Date</label>
        <DateInput
          id="birthDate"
          control={values.birthDate}
          max={pipe(new Date(), date.addYears(-18), Number)}
        />
        <InputFeedback control={values.birthDate} />
      </fieldset>

      <footer class="flex j-center">
        <button type="submit">Register</button>
      </footer>
    </form>
  )
}
