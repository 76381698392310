import { LogLevel } from '@/std/logger'

export type Config = {
  env: 'dev' | 'staging' | 'prod'
  logLevel: LogLevel
  apiUrl: string
  // telemetry: {
  //   traceEndpoint: string
  //   metricsEndpoint: string
  // }
}

// @ts-ignore injected by bundler
export const config: Config = __CONFIG__
