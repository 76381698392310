import { IconProps } from './props'
import { _BurgerIcon } from './svg/burger.solid'
import { _EditIcon } from './svg/edit.solid'
import { _EllipsisMenuIcon } from './svg/ellipsis-menu.solid'
import { _LogoutIcon } from './svg/logout.solid'
import { _SaveIcon } from './svg/save.solid'
import { _TrashIcon } from './svg/trash.solid'

const icons = {
  burger: _BurgerIcon,
  edit: _EditIcon,
  ellipsisMenu: _EllipsisMenuIcon,
  logout: _LogoutIcon,
  save: _SaveIcon,
  trash: _TrashIcon,
} as const

export type IconName = keyof typeof icons

type Props = Partial<IconProps> & {
  name: IconName
}

export const _Icon = (props: Props) => {
  const Component = icons[props.name]
  return <Component size={props.size ?? '1em'} />
}
