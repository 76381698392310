import { preventDefault } from '@/std/browser'
import { O } from '@/std/data'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { pipe } from '@/std/function'
import { ReadonlyState } from '@/std/reactivity'
import { RR, RemoteAction, TR } from '@/std/remote'
import { uuid } from '@/std/uuid'
import { Pal, PalId } from '../../entity/Pal'
import { Relation, Relationship } from '../../entity/PalRelation'
import { DB, putPal } from '../api'

export type PalFormModel = {
  readonly values: FormGroup<{
    firstName: FormControl<string, 'Required'>
    lastName: FormControl<string, 'Required'>
    birthDate: FormControl<O.Option<Date>>
    relationType: FormControl<Relation['relationship']['type']>
    relationSince: FormControl<O.Option<Date>>
    relationUntil: FormControl<O.Option<Date>>
    relationNature: FormControl<string>
  }>
  readonly submitState: ReadonlyState<RR.RemoteResult<unknown, unknown>>
  submit: () => Promise<void>
}

type Deps = {
  db: () => DB
  sync: (db: DB) => TR.TaskResult<unknown, unknown>
  initialValues?: {
    pal: Pal
    relation: Relation
  }
}
export const PalFormModel = ({ db, sync, initialValues }: Deps) => {
  const relationship = initialValues?.relation.relationship
  const values = FormGroup({
    firstName: FormControl(initialValues?.pal.firstName ?? '', [
      nonEmpty('Required'),
    ]),
    lastName: FormControl(initialValues?.pal.lastName ?? '', [
      nonEmpty('Required'),
    ]),
    birthDate: FormControl(initialValues?.pal.birthDate ?? O.None<Date>()),
    relationType: FormControl(relationship?.type ?? 'friend'),
    relationSince: FormControl(
      relationship && relationship.type !== 'family'
        ? O.Some(relationship.since)
        : O.None(),
    ),
    relationUntil: FormControl(
      relationship && relationship.type !== 'family'
        ? relationship.until
        : O.None(),
    ),
    relationNature: FormControl(
      relationship?.type === 'family' ? relationship.nature : '',
    ),
  })
  const action = RemoteAction(sync)

  const model: PalFormModel = {
    values,
    submitState: action.state,
    submit: preventDefault(async () => {
      const pal = PalFromValues()
      const relation = RelationFromValues(pal)
      if (O.isNone(relation)) return
      const next = putPal(db(), { pal, relation: relation.value })
      await action.trigger(next)
    }),
  }
  return model

  function PalFromValues(): Pal {
    return {
      birthDate: values.birthDate(),
      createdAt: initialValues?.pal.createdAt ?? new Date(),
      deathDate: initialValues?.pal.deathDate ?? O.None(),
      firstName: values.firstName(),
      id: initialValues?.pal.id ?? (uuid() as PalId),
      lastName: values.lastName(),
      updatedAt: new Date(),
    }
  }

  function RelationFromValues(pal: Pal): O.Option<Relation> {
    return pipe(
      RelationshipFromValues(),
      O.map(
        (relationship): Relation => ({
          createdAt: initialValues?.relation.createdAt ?? new Date(),
          oldest: db().selfPalId,
          youngest: pal.id,
          relationship,
          updatedAt: new Date(),
        }),
      ),
    )
  }

  function RelationshipFromValues(): O.Option<Relationship> {
    const type = values.relationType()
    if (type === 'family')
      return O.Some({ type, nature: values.relationNature() })
    const since = values.relationSince()
    if (O.isNone(since)) return O.None()
    return O.Some<Relationship>({
      type,
      since: since.value,
      until: values.relationUntil(),
    })
  }
}
