import {
  ComponentProps,
  JSX,
  createContext,
  createUniqueId,
  useContext,
} from 'solid-js'

type Props = {
  position?: 'top' | 'bottom'
  listboxId?: string
  trigger: JSX.Element
  menu: JSX.Element
}

const context = createContext<{ id: string }>(undefined, { name: 'Dropdown' })

export const _Dropdown = (props: Props) => {
  const id = props.listboxId ?? createUniqueId()

  return (
    <context.Provider value={{ id }}>
      <div class={`dropdown menu-${props.position ?? 'bottom'}`}>
        {props.trigger}
        {props.menu}
      </div>
    </context.Provider>
  )
}

_Dropdown.Trigger = (props: ComponentProps<'button'>) => {
  const ctx = useContext(context)
  if (!ctx) throw new Error('invalid usage of Dropdown.Trigger')
  return (
    <button {...props} role="combobox" aria-haspopup aria-controls={ctx.id} />
  )
}

_Dropdown.Menu = (props: ComponentProps<'div'>) => {
  const ctx = useContext(context)
  if (!ctx) throw new Error('invalid usage of Dropdown.Menu')
  return <div class="card" {...props} role="listbox" id={ctx.id} />
}
