import { solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { match, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Dropdown, Failure, Icon, Loader, Logo } from '@palnet/ui-kit'
import { PalsAppModel } from './app'
import { PalForm } from './components/PalForm.solid'
import { PalStoryForm } from './components/PalStoryForm.solid'
import { PalStoryList } from './components/PalStoryList.solid'
import { PalsList } from './components/PalsList.solid'
import { RegisterForm } from './components/RegisterForm.solid'

type Props = {
  model: PalsAppModel
}

export const PalsApp = (props: Props) => {
  solidRemoteResource(props.model.resource)
  const view = solidState(props.model.view)

  return (
    <div class="page-background flex column" style="min-block-size: 100vb;">
      <header class="page-header">
        <span class="flex a-center gap-s">
          <Logo.Icon height="2em" />
          <Logo.Tagline class="logo-tagline" />
        </span>
        <nav class="flex">
          <Dropdown
            trigger={<Dropdown.Trigger class="nav-link">Pals</Dropdown.Trigger>}
            menu={
              <Dropdown.Menu>
                <button
                  role="option"
                  onClick={() => props.model.openPalsForm()}
                >
                  Add
                </button>

                <button role="option" onClick={props.model.viewList}>
                  List
                </button>
              </Dropdown.Menu>
            }
          />
          <Dropdown
            trigger={
              <Dropdown.Trigger class="nav-link">Stories</Dropdown.Trigger>
            }
            menu={
              <Dropdown.Menu>
                <button role="option" onClick={props.model.openStoryForm}>
                  Add
                </button>
                <button role="option" onClick={props.model.viewStories}>
                  List
                </button>
              </Dropdown.Menu>
            }
          />
          <button class="nav-link" onClick={props.model.logOut}>
            <Icon name="logout" />
          </button>
        </nav>
      </header>

      <div class="padded flex column gap">
        <div>
          <button
            class="link"
            onClick={props.model.viewStories}
            hidden={pipe(
              view(),
              RR.toOption,
              O.flatten,
              O.map(
                (view) => view.name === 'stories' || view.name === 'register',
              ),
              O.unwrapOr(() => false),
            )}
          >
            {'Back to stories'}
          </button>
        </div>
        {pipe(
          view(),
          RR.fold2(
            () => <Loader />,
            (error) => <Failure error={error} />,
            match('name')({
              palForm: ({ model }) => {
                const hasFirstNameAtInit = !!model.values.firstName()
                return (
                  <div class="card">
                    <header class="text-center">
                      <h2>
                        {hasFirstNameAtInit ? 'Edit Pal Info' : 'Add a Pal'}
                      </h2>
                    </header>
                    <PalForm model={model} />
                  </div>
                )
              },
              storyForm: ({ model }) => (
                <PalStoryForm class="card flex column gap" model={model} />
              ),
              stories: ({ model }) => (
                <PalStoryList class="card flex column gap" model={model} />
              ),
              list: ({ model }) => (
                <PalsList class="card flex column gap" model={model} />
              ),
              register: ({ model }) => (
                <div class="flex column gap">
                  <div class="card">
                    <h2>Hello newcomer!</h2>
                    <p class="block">
                      <small>
                        Not a newcomer? Try another password by clicking{' '}
                        <button onClick={props.model.logOut}>here</button>
                      </small>
                    </p>
                  </div>
                  <RegisterForm class="card" model={model} />
                </div>
              ),
            }),
          ),
        )}
      </div>
    </div>
  )
}
