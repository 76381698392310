import { FormControl } from '@/std/form-control'
import { ReadonlyState, mapState } from '@/std/reactivity'
import { Pal } from '../../entity/Pal'
import { Relation } from '../../entity/PalRelation'
import { DB, listPalsOfSelf } from '../api'

export type PalsListModel = {
  list: ReadonlyState<{ pal: Pal; relation: Relation }[]>
  search: FormControl<string>
  onEditPal: (options: { pal: Pal; relation: Relation }) => void
}

type Deps = {
  db: DB
  onEditPal: (options: { pal: Pal; relation: Relation }) => void
}

export const PalsListModel = ({ db, onEditPal }: Deps) => {
  const search = FormControl('')
  const model: PalsListModel = {
    search,
    list: mapState(search, listPalsOfSelf(db)),
    onEditPal,
  }
  return model
}
